







































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {  QueryOrderDetails, QueryPartnerShop, QuerySystemConfigByKey} from '@/api'
import constant from '@/utils/constant'
import {getUser } from '@/store/userStore'
import { Message } from 'element-ui'
import {digitUppercase} from '@/utils/NumberToChinese'
const {getLodop}= require('@/plugins/LodopFuncs')


@Component({})
export default class PrintBill extends Vue {
  // 是否是审核
  @Prop({type: Boolean,default: false}) private audit!: boolean
  private constant = constant
  private show = false
  private data: any = {}
  private tableData: Array<any> = []
  private shopName = ""
  private billPhone = "-"
  private printImgWidth = 300
  // 打印票据
  private  printOrder = {
    id: 'printBill',
    // id: 'printImg',
    popTitle: '打印', // 打印配置页上方标题
    extraHead: '', //最上方的头部文字，附加在head标签上的额外标签,使用逗号分隔
    preview: '', // 是否启动预览模式，默认是false（开启预览模式，可以先预览后打印）
    previewTitle: '', // 打印预览的标题（开启预览模式后出现）,
    previewPrintBtnLabel: '', // 打印预览的标题的下方按钮文本，点击可进入打印（开启预览模式后出现）
    zIndex: '', // 预览的窗口的z-index，默认是 20002（此值要高一些，这涉及到预览模式是否显示在最上面）   
    // previewBeforeOpenCallback() {}, //预览窗口打开之前的callback（开启预览模式调用）
    // previewOpenCallback() {}, // 预览窗口打开之后的callback（开启预览模式调用）
    // beforeOpenCallback() {}, // 开启打印前的回调事件
    // openCallback() {}, // 调用打印之后的回调事件
    // closeCallback() {}, //关闭打印的回调事件（无法确定点击的是确认还是取消）
    // url: '',
    // standard: '',
    // extraCss: '',
  }
  
  private get user(){
    return getUser().user
  }
  private get toChinese(){
    // 大写金额
    return digitUppercase(Number(this.totalCost))
  }
  private get totalCount() {
    if(this.tableData == null || this.tableData.length == 0){
      return 0;
    } 
    let count = 0;
    this.tableData.forEach(e=>{
      count += Number(e.newQty)
    })
    return count
  }

  private get totalCost() {
    if(this.tableData == null || this.tableData.length == 0){
      return 0.0;
    } 
    let count = 0.0;
    this.tableData.forEach(e=>{
      count += Number(e.newAmount)
    })
    return count.toFixed(2)
  }
  
  public created(){
    this.loadSystemConfig()
  }

  private async loadSystemConfig(){
    const res = await QuerySystemConfigByKey({configKey:'billWidth'})
    this.printImgWidth = Number(res.configValue)
  }
  
  public beforeUpdate(){
    // this.disablePrint = true
  }
  public updated() {
    // this.loadImage()
  }


  public async loadData() {
    const param: any = { orderCode: this.data.orderCode}
    const result = await QueryOrderDetails(param)
    let td = result.list
    td = td.filter((item) => {
      return item.newAmount > 0
    })
    
    this.tableData = td
  }

  @Emit('close')
  private close() {
    this.show = false
    this.data = {}
  }
  
  public open(param: any,td: Array<any>) {
    this.show = true
    this.data = {...param}
    let td2 = td.filter((item) => {
      return item.newAmount > 0
    })
    const td3: Array<any> = []
    td2.forEach((item)=>{
      const bs = item.batchNum.split(',')
      if(bs.length<2){
        td3.push(item)
        return
      }
      const bcs = item.batchCount.split(',')
      const vs = item.validity.split(',')
      const ps = item.prodDate.split(',')
      const r1: any = {...item};
      r1.batchCount = bcs[0]
      r1.newQty = bcs[0]
      r1.validity = vs[0]
      r1.prodDate = ps[0]
      r1.batchNum = bs[0]
      r1.newAmount = r1.newQty * r1.originalPrice
      r1.newAmount = r1.newAmount.toFixed(2)
      const r2: any = {...item};
      r2.batchCount = bcs[1]
      r2.newQty = bcs[1]
      r2.validity = vs[1]
      r2.prodDate = ps[1]
      r2.batchNum = bs[1]
      r2.newAmount = r2.newQty * r2.originalPrice
      r2.newAmount = r2.newAmount.toFixed(2)
      td3.push(r1)
      td3.push(r2)
    })
    // 拆分
    console.log(td3)
    this.tableData = td3
    this.loadShop()
    // this.loadData()
  }

  private async loadShop(){
    const res = await QueryPartnerShop({id:this.data.partnerShopId})
    const shop = res.list[0]
    this.shopName = shop.billTitle
    this.billPhone = shop.billPhone
  }

  // clodop打印 
  private  clodopPrint(){
    
    console.log(getLodop)
   
    // let self = this
    let idName = "printBill"
    
    // debugger
    let LODOP = getLodop() //主要代码
    LODOP.PRINT_INIT("票据打印")
    LODOP.SET_PRINT_PAGESIZE(3, this.printImgWidth+'mm')
    LODOP.ADD_PRINT_HTM(
      0,
      0,
      '100%',
      '100%',
      (document as any).getElementById(idName).innerHTML //获取要打印部分html
    )
    //以上为声明打印参数，具体请参照官网示例
    //以下是成功加入打印队列之后的回调，如果不用刻意不看，直接执行LODOP.PRINT()
    LODOP.SET_PRINT_MODE('CATCH_PRINT_STATUS', true)

    if (LODOP.CVERSION) {
      //判断c_lodop是否存在，安装了c-lodop就会存在
      LODOP.On_Return = function (TaskID: any, Value: any) {
        // console.log('TaskID:' + TaskID)
        console.log('Value:' + Value) //job代码
        Message.success("打印成功")
      }
      LODOP.PRINT() //这是执行打印（无预览）
      // LODOP.PREVIEW() // 这是有预览打印
      return
    } else {
      console.log('c-lodop出错了')
      Message.success("打印出错了")
    }
  }
}
